<template>
  <v-data-table
    must-sort
    fixed-header
    :loading="loading"
    :headers="headers"
    :items="allergen_groups"
    :options.sync="options"
    :search="options.search"
    :footer-props="{
      itemsPerPageOptions: [ 15, 25, 50 ],
      itemsPerPageText: `Allergen groups ${ $t('per page') }:`,
      showFirstLastPage: true
    }"
    class="elevation-2"
  >
    <template #top>
      <v-row
        justify="space-between"
        class="pt-3 px-3 mb-n3 "
      >
        <template v-if="title">
          <v-col
            class="text-h6"
          >
            {{ title }}
          </v-col>
          <v-spacer />
        </template>
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-col class="shrink">
          <v-btn
            v-if="$auth.check({ allergens: 'edit'})"
            color="success"
            fab
            small
            class="mt-n11"
            @click="editGroup = {}"
          >
            <v-icon small>
              fal fa-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <edit-allergen-group
        :group="editGroup"
        @close="editGroup = null"
        @updated="fetchAllergenGroups"
      />
    </template>
    <template #item.relevant="{ item }">
      <v-icon small>
        {{ item.relevant ? 'fal fa-check' : 'fal fa-times' }}
      </v-icon>
    </template>
    <template #item.displayed="{ item }">
      <v-icon small>
        {{ item.displayed ? 'fal fa-check' : 'fal fa-times' }}
      </v-icon>
    </template>
    <template #item.drug_classes="{ item }">
      <v-chip
        v-for="drug_class in item.drug_classes"
        :key="`drug_class-${drug_class.id}`"
        small
        label
        class="me-1"
        v-text="drug_class.name"
      />
    </template>
    <template #item.lab_results_count="{ item }">
      <router-link :to="{ name: 'Lab Results', query: { allergen: item.name } }">
        {{ item.lab_results_count }}
      </router-link>
    </template>
    <template
      #item.actions="{ item }"
    >
      <v-btn
        small
        ripple
        icon
        plain
        @click="editGroup = item"
      >
        <v-icon>fal fa-ellipsis-v</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
  export default {
    components: {
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
      EditAllergenGroup: () => import('@/components/allergen/EditAllergenGroup.vue'),
    },
    props: {
      search: {
        type: String,
        default: null,
      },
      title: {
        type: String,
        default: null,
      },
    },
    metaInfo: {
      title: 'Allergen Groups',
    },
    data () {
      return {
        loading: false,
        editGroup: null,
        options: {
          filter: this.$route.query,
          search: null,
          sortBy: ['name'],
          sortDesc: [false],
        },
        totalItems: null,
        headers: [
          {
            text: 'Group',
            value: 'name',
          },
          {
            text: 'Drug Classes',
            value: 'drug_classes',
          },
          {
            text: 'Relevant',
            value: 'relevant',
          },
          {
            text: 'Displayed',
            value: 'displayed',
          },
          {
            sortable: false,
            align: 'right',
            value: 'actions',
          },
        ],
        allergen_groups: [],
        drug_classes: [],
        groups: [],
      }
    },
    mounted () {
      this.fetchAllergenGroups()
    },
    methods: {
      fetchAllergenGroups () {
        this.loading = true
        this.axios.get('admin/allergens/groups').then((response) => {
          this.allergen_groups = response.data
          // if (response.data.last_page < response.data.current_page) {
          //   this.options.page = response.data.last_page
          //   this.fetchAllergenGroups()
          //   return
          // }
          // this.$set(this.options, 'page', response.data.current_page)
          // this.$set(this.options, 'itemsPerPage', Number(response.data.per_page))
          // this.totalItems = response.data.total
          // this.allergen_groups = response.data.data
        }).catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.inner-text {
  height: 100%;
  width: 100%;
  font-size: 4em;
  padding-top: 1.1em;

  span {
    max-width: 100px;
  }
}
</style>
